<template>
  <v-card class="global-contain" flat>
    <v-row class="pl-10" align="center">
      <v-col cols="2" :key="i" v-for="(item, i) in comparisonData">
        <v-card
          outlined
          class="global-card4 pa-6 mx-auto d-flex flex-column justify-center align-center"
          :key="i"
        >
          <v-icon
            small
            @click="deleteItem(i)"
            class="global-icon2"
            color="black"
          >
            mdi-close
          </v-icon>

          <v-img
            class="global-img2"
            :src="item[logoName]"
            @error="handleLogoError($event, item)"
            contain
            position="center"
          />
        </v-card>
      </v-col>
      <v-col cols="2" v-if="comparisonData.length < 4">
        <v-card
          color="#f2f2f2"
          outlined
          class="global-card4 mx-auto d-flex flex-column justify-center align-center"
        ></v-card>
      </v-col>

      <v-btn
        v-if="comparisonData.length > 0"
        @click="deleteAll"
        smalltext
        text
        class="blue--text text-capitalize"
      >
        <v-icon small class="mr-2 global-trashbtn">
          mdi-trash-can-outline</v-icon
        >
        Limpiar
      </v-btn>
      <v-spacer></v-spacer>
      <v-col cols="3">
        <h3
          class="global-textStyle3 global-comparetxt"
          v-if="comparisonData.length > 0"
        >
          Comparar {{ comparisonData.length }} ofertas
        </h3>
        <h3 class="global-textStyle3" v-else>No hay ofertas seleccionadas</h3>
      </v-col>
      <div style="position: absolute; right: 0" class="mr-15">
        <buttonprincipal
          label="Comparar"
          :disabled="comparisonData.length >= 2 ? false : true"
          v-on:click.native="Comparar()"
          color="#0077c8"
        />
      </div>
    </v-row>
  </v-card>
</template>
<script>
import buttonprincipal from "@/components/Button/Btn-Principal";
import LogoRequestMixin from "@/Mixins/LogoRequestMixin.js";
export default {
  mixins: [LogoRequestMixin],
  components: {
    buttonprincipal,
  },
  props: {
    comparisonData: {
      type: [Array, Object],
    },
    Comparar: {
      type: Function,
    },
    deleteAll: {
      type: Function,
    },
  },
  data() {
    return {
      items: this.comparisonData,
    };
  },
  methods: {
    deleteItem(item) {
      this.comparisonData.splice(item, 1);
      this.$emit("compare", this.comparisonData);
    },
  },
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
.spacer {
  flex-grow: 0.4 !important;
}
.v-icon.v-icon::after {
  background-color: white;
}
.col-2 {
  flex: 0 0 8%;
  max-width: 16.6666666667%;
}

@media screen and (min-width: 1920px) {
  :deep(.spacer) {
    flex-grow: 1 !important;
  }
}
</style>
