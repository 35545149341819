<template>
  <div v-if="open">
    <v-dialog
      persistent
      v-model="Open"
      max-width="880px"
      height="auto"
      attach="#main-container"
      name="dialog-solicitud-descuento"
    >
      <v-card class="global-modalCard6" name="card-solicitud-descuento">
        <v-layout wrap class="pa-4">
          <v-flex>
            <div class="d-flex">
              <p class="global-span1" v-if="success">Solicitar descuento</p>
              <h1 class="global-titleStyle14 mt-10 ml-10" v-if="!success">
                ¡Solicitud exitosa!
              </h1>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeBtn">
                <v-icon class="global-icon7">mdi-close</v-icon>
              </v-btn>
            </div>
            <v-card outlined class="global-card13 d-flex" v-if="success">
              <v-col cols="1" class="ml-4">
                <v-icon color="#0077C8" x-large
                  >mdi-bookmark-check-outline</v-icon
                >
              </v-col>

              <v-col cols="10">
                <h4 class="global-archtxt">
                  Prima referencial {{ Info.moneda_local.precio | moneyFormat }}
                </h4>
                <p class="global-SubDescontoStyle mt-2">
                  Prima referencial con descuento
                  {{
                    (Info.moneda_local.precio -
                      (Info.moneda_local.precio *
                        this.select_Porcentaje.selected) /
                        100)
                      | moneyFormat
                  }}
                </p>
              </v-col>
            </v-card>
            <h4 class="mt-8 ml-10 global-subtitleStyle10" v-if="!success">
              La solicitud de descuento enviada<br />
              está siendo revisada. Favor de esperar <br />
              y consultar al correo en estos días.
            </h4>
          </v-flex>

          <v-flex id="flex" lg10>
            <v-img
              max-width="350px"
              id="BackgroundSvgs"
              src="@/assets/modalImages/Vector.png"
            />
            <v-form ref="descuento">
              <v-layout class="mt-6">
                <v-flex lg4 class="ma-2" name="flex_porsentaje">
                  <SelectLabel
                    v-if="success"
                    :fields="select_Porcentaje"
                    color="#0077c8"
                    :rules="select_Porcentaje.rules"
                  />
                </v-flex>
                <v-flex lg4 class="ma-2" name="flex_cargo">
                  <SelectLabel
                    v-if="success"
                    :fields="select_cargo"
                    color="#0077c8"
                    :rules="select_cargo.rules"
                  />
                </v-flex>
                <v-flex lg4 class="ma-2" name="flex_supervisores">
                  <SelectLabel
                    v-if="success"
                    :fields="select_supervisores"
                    color="#0077c8"
                    :rules="select_supervisores.rules"
                  />
                </v-flex>
                <v-flex lg4 class="ma-2" name="flex_motivo">
                  <SelectLabel
                    v-if="success"
                    :fields="select_motivo"
                    color="#0077c8"
                    :rules="select_motivo.rules"
                  />
                </v-flex>
              </v-layout>
            </v-form>
          </v-flex>
        </v-layout>
        <v-img
          class="mt-8 global-image8"
          v-if="!success"
          max-width="230px"
          width="200"
          src="../../assets/modalImages/pana.png"
        />
        <Button
          v-if="!success"
          v-on:click.native="closeitall"
          class="global-Btn4 mr-16"
          label="Entendido"
          color="#0077c8"
        />

        <Button
          :loading="loader"
          v-if="success"
          v-on:click.native="SolicitarProposta"
          class="global-Btn4 mr-16"
          label="Solicitar"
          color="#0077c8"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import SelectLabel from "@/components/Inputs/SelectLabel";
import Button from "@/components/Button/Btn-Principal";
export default {
  props: {
    open: {
      type: Boolean,
    },
    closeBtn: {
      type: Function,
    },
    openfinalmodal: {
      type: Function,
    },
    Info: {
      type: [Object, Array],
    },
    Ofertas: {
      type: [Object, Array],
    },
  },
  components: {
    Button,
    SelectLabel,
  },
  data() {
    return {
      loader: false,
      form: [],
      success: true,
      Open: true,
      dialog: true,
      nSucursal: {},

      select_Porcentaje: {
        label: "Porcentaje",
        answers: [
          { label: "10%", value: 10 },
          { label: "13%", value: 13 },
          { label: "15%", value: 15 },
          { label: "18%", value: 18 },
        ],
        selected: "",

        id: [],
        rules: [(v) => !!v || "Campo requerido"],
      },

      select_cargo: {
        label: "A cargo de",
        answers: [
          { label: "Dealer", value: "dealer" },
          { label: "Mesos", value: "mesos" },
          { label: "Compañia", value: "compania" },
        ],
        selected: "",
        rules: [(v) => !!v || "Campo requerido"],
      },
      select_supervisores: {
        label: "Supervisor",
        answers: [],
        selected: "",
        rules: [(v) => !!v || "Campo requerido"],
      },
      select_motivo: {
        label: "Motivo",
        answers: [
          { label: "Competencia", value: "competencia" },
          { label: "Oferta Personal", value: "oferta" },
          { label: "Igualar Cotización", value: "cotizacion" },
          { label: "Acuerdo Comercial", value: "comercial" },
          { label: "Referido", value: "referido" },
          { label: "Campaña Intelligent", value: "intelli" },
        ],
        selected: "",
        rules: [(v) => !!v || "Campo requerido"],
      },
    };
  },
  methods: {
    closeitall() {
      this.closeBtn(), (this.success = true);
    },

    SolicitarProposta() {
      if (this.$refs.descuento.validate()) {
        (this.loader = true),
          // Validar si existe ventas de tercero
          // Hay que definir si no hay ventas de terceros, donde sacar la sucursal.
          (this.nSucursal = {});
        if (this.Ofertas.cotizacion.venta_terceros.sucursal) {
          this.nSucursal.sucursal =
            this.Ofertas.cotizacion.venta_terceros.sucursal;
          this.nSucursal.sucursal_descripcion =
            this.Ofertas.cotizacion.venta_terceros.sucursal_descripcion;
        } else {
          // definir donde sacar si no existe cotizacion de terceros
          this.nSucursal.sucursal = "";
          this.nSucursal.sucursal_descripcion = "";
        }

        axios({
          url: "descuentos",
          method: "POST",
          data: {
            rut: this.Ofertas.cotizacion.numero_documento,
            usuario_nombre: this.Ofertas.cotizacion.cliente,
            sucursal: {
              sucursal: this.nSucursal.sucursal,
              sucursal_descripcion: this.nSucursal.sucursal_descripcion,
            },
            cargo: this.select_cargo.selected,
            descuento_porcentaje: this.select_Porcentaje.selected,
            cotizacion: this.Ofertas.cotizacion.numero_cotizacion,
            plan: this.Info.plan,
            supervisor: this.select_supervisores.selected,
            motivo: this.select_motivo.selected,
          },
        })
          .then((response) => {
            if (response) {
              this.success = false;
              this.$emit("descuentosolicitado", true);
              this.loader = false;
            }
          })
          .catch((err) => {
            if (err) {
              this.loader = false;
            }
          });
      }
    },
    async getConvenio() {
      this.canales.answers.map((it) => {
        this.select_cargo.answers.push({ label: it.label, value: it.value });
      });
      /*for (let i = 0; i < convenios.length; i++) {
        this.select_cargo.answers = [
          {
            label: convenios[i].convenio,
            value: convenios[i].convenio,
          },
        ];
      }*/
    },
    // Jean Pierre
    // 03-10-2022
    // Metodo, que obtiene los supervisores desde el back que aprueban los descuentos
    getSupervisor() {
      axios({
        url: "/descuentos/admin-descuentos",
        method: "GET",
      })
        .then((response) => {
          if (response) {
            // Verificar el formato que responde el back

            this.select_supervisores.answers = response.data.data.result.map(
              function (sup) {
                let nsup = {};
                nsup.label = sup.nombre;
                nsup.value = sup.id;
                return nsup;
              }
            );
            //this.success = false;
            this.loader = false;
          }
        })
        .catch((err) => {
          if (err) {
            this.loader = false;
          }
        });
    },
  },
  computed: {
    sucursal() {
      return this.$store.getters.sucursal;
    },
    canales() {
      return this.$store.getters.getCanales;
    },
    convenio() {
      return this.$store.getters.convenio;
    },
  },
  watch: {
    convenio() {
      if (this.convenio) {
        this.$emit("descuentosolicitado", false);

        this.getConvenio();
      }
    },
  },
  created() {
    this.getConvenio();
    this.getSupervisor();
  },
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
#BackgroundSvgs {
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
}

#flex {
  margin-left: -10px;
}
</style>
