<template>
  <div class="text-center">
    <v-dialog persistent :value="open" max-width="950" attach="#main-container">
      <v-card
        class="overflow-y-hidden overflow-x-hidden"
        :loading="loadingpropuesta || loadingquot || loadShare"
        height="645"
      >
        <div class="d-flex justify-space-between pt-2 px-6">
          <h4>Detalle del producto</h4>
          <v-btn icon :disabled="loadingpropuesta" @click="$emit('close')">
            <v-icon class="global-pointer">mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="d-flex">
          <cardPlan
            :Cotizacion="cotizacion"
            :SeguroName="Seguro"
            :SeguroDetails="Infor"
            :alineacion="alineacion"
          />
          <div class="d-flex justify-end flex-grow-1 w-30 align-end pr-8">
            <v-btn
              color="primary"
              :disabled="loadingpropuesta || loadShare"
              @click="sendQuotation(Infor)"
              text
              class="text-none"
              ><v-icon left>mdi-share-variant</v-icon> Compartir
              Simulación</v-btn
            >
          </div>
        </div>

        <div class="d-flex flex-column justify-center mt-4">
          <div class="d-flex"></div>
          <div v-if="descuentosolicitado">
            <h3>Solicitud descuento enviada</h3>
          </div>

          <!-- <cardPlan :SeguroName="Seguro" :SeguroDetails="Infor" /> -->
        </div>

        <div
          class="w-100 main-background-color py-4 px-5 d-flex justify-space-between"
        >
          <v-row>
            <v-col>
              <v-card flat class="bordered" height="350">
                <div class="card-content overflow-y-auto">
                  <v-card-title class="cardTitle">
                    <h4>Coberturas</h4>
                  </v-card-title>
                  <v-card-text>
                    <div v-for="(item, i) in coberturas" :key="i" class="my-4">
                      <div class="d-flex align-center">
                        <div><v-icon>mdi-account-check</v-icon></div>
                        <div class="ml-4">
                          <span class="bold">{{ item.titulo }}</span
                          ><br />
                          <span>{{ item.descripcion }}</span>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </div>
              </v-card>
            </v-col>
            <v-col>
              <v-card flat class="bordered" height="350">
                <div class="card-content overflow-y-auto">
                  <v-card-title class="cardTitle">
                    <h4>Exclusiones</h4>
                  </v-card-title>
                  <v-card-text>
                    <div v-if="exclusiones.length > 0">
                      <div
                        v-for="(item, i) in exclusiones"
                        :key="i"
                        class="my-2"
                      >
                        <div class="d-flex align-center">
                          <div>
                            <v-icon>mdi-account-minus</v-icon>
                          </div>
                          <div class="ml-4">
                            <span class="bold">{{ item.titulo }}</span
                            ><br />
                            <span>{{ item.descripcion }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>Este producto no tiene exclusiones</div>
                  </v-card-text>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12">
              <div class="float-right mb-3">
                <v-btn
                  color="primary"
                  class="ml-3 text-none px-10"
                  :loading="loadingquot"
                  outlined
                  rounded
                  :disabled="loadingpropuesta"
                  @click="GenerateQuotation(Infor)"
                >
                  Descargar simulación
                </v-btn>
                <v-btn
                  color="primary"
                  class="ml-3 text-none px-10"
                  rounded
                  :loading="loadingpropuesta"
                  :disabled="disabledContinue"
                  @click="CreatePropuesta(Infor)"
                >
                  Continuar
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>

        <!-- <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1" class="pa-3">
            <SecureDetail :secure="Infor.coberturas_exclusiones" />
          </v-tab-item>
        </v-tabs-items> -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import SecureDetail from "@/components/Cards/Secure-detail-card.vue";
import cardPlan from "@/components/Cards/Plan-card";

// import btnsecondary from "@/components/Button/Btn-Secundario";
export default {
  name: "ModalDetalleDelProducto",
  props: {
    cotizacion: Object,
    open: {
      type: Boolean,
      require: true,
    },
    Infor: {
      type: [Array, Object],
    },
    Descuento: {
      type: Function,
      default(Infor) {
        return Infor;
      },
    },
    descuentosolicitado: {
      type: Boolean,
    },
    loadingpropuesta: {
      type: Boolean,
    },
    loadingquot: {
      type: Boolean,
    },
    loadShare: {
      type: Boolean,
    },
    share: {
      type: Boolean,
      default: false,
    },
    download: {
      type: Boolean,
      default: false,
    },
    discountStatus: {
      type: Boolean,
    },
    disabledContinue: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    cardPlan,
  },
  data() {
    return {
      tab: "tab-1",
      card: "0212",
      data: "2021",
      getDiscount: false,
      Seguro: "Seguro automotriz Bci",
      Detail: "Deducible UF 15 - Prima mensual de $15.129",
      //alineacion: "izquierda"
    };
  },
  methods: {
    CreatePropuesta(v) {
      this.$emit("CreatePropuesta", v);
    },

    GenerateQuotation(item) {
      this.$emit("download", item);
    },

    solicitarDescuento(Infor) {
      this.Descuento(Infor);
    },
    sendQuotation(item) {
      this.$emit("share", item);
    },
  },

  computed: {
    coberturas() {
      return (this.Infor?.coberturas_exclusiones ?? []).filter(
        (item) => item.tipo === "C"
      );
    },
    exclusiones() {
      return (this.Infor?.coberturas_exclusiones ?? []).filter(
        (item) => item.tipo === "E" || item.tipo === "O"
      );
    },
    alineacion() {
      return this.Infor?.moneda_deducible?.alineacion ?? "izquierda";
    },
  },
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";

.cardTitle {
  position: sticky;
  top: 0;
  background-color: #fff;
}
.card {
  overflow-y: hidden !important;
  border-radius: 10px;
}
</style>
