import { render, staticRenderFns } from "./Comparison-bar.vue?vue&type=template&id=0264c6a3&scoped=true"
import script from "./Comparison-bar.vue?vue&type=script&lang=js"
export * from "./Comparison-bar.vue?vue&type=script&lang=js"
import style0 from "./Comparison-bar.vue?vue&type=style&index=0&id=0264c6a3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0264c6a3",
  null
  
)

export default component.exports