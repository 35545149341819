<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <v-btn
          text
          color="primary"
          class="text-none"
          @click="goBack()"
          :disabled="!pageLoaded"
        >
          <v-icon small left>mdi-arrow-left-bold-outline</v-icon>
          Volver al detalle del cliente
        </v-btn>
      </v-col>
      <v-col
        cols="6"
        class="text-right"
        v-if="CotizacionData?.cotizacion?.numero_propuesta"
        @click="handlerClickContinue()"
      >
        <v-btn color="primary" text class="text-none"
          >Continuar con la contratación
          <v-icon small right>mdi-arrow-right-bold-outline</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <div v-if="!withError">
      <v-row class="headerSticky">
        <v-col>
          <v-card elevation="0" height="60" tile class="bordered">
            <div class="card-content">
              <v-card-text>
                <div class="d-flex justify-space-between">
                  <div>
                    <h4 class="title-color">
                      Resultados de la simulación
                      <span
                        >n°
                        {{
                          CotizacionData?.cotizacion?.numero_cotizacion ??
                          id ??
                          "--"
                        }}</span
                      >
                    </h4>
                  </div>

                  <div class="d-flex">
                    <h5>
                      {{ CotizacionData?.cotizacion?.cliente ?? "" }}
                    </h5>
                    <span class="ml-3">
                      <span v-if="isChile">{{
                        CotizacionData?.cotizacion?.numero_documento ??
                        "" | rutFormat
                      }}</span>
                      <span v-else>{{
                        CotizacionData?.cotizacion?.numero_documento ?? ""
                      }}</span>
                    </span>
                  </div>
                  <template
                    v-if="CotizacionData?.cotizacion?.categoria_codigo == '1'"
                  >
                    <div>
                      <span class="bold h5 title-color">Marca: </span>
                      <span class="h5">
                        {{
                          CotizacionData?.cotizacion?.marca_descripcion ?? ""
                        }}
                      </span>
                    </div>
                    <div>
                      <span class="bold h5 title-color"> Modelo: </span>
                      <span class="h5">
                        {{
                          CotizacionData?.cotizacion?.modelo_descripcion ?? ""
                        }}
                      </span>
                    </div>
                    <div>
                      <span class="bold h5 title-color">
                        {{ langText?.patent }}:
                      </span>
                      <span class="h5" v-if="isPeru">
                        {{
                          (CotizacionData?.cotizacion?.patente === "FACTURA"
                            ? "En Trámite"
                            : CotizacionData?.cotizacion?.patente) ?? ""
                        }}
                      </span>
                      <span class="h5" v-else>
                        {{ CotizacionData?.cotizacion?.patente ?? "" }}
                      </span>
                    </div>
                    <div>
                      <span class="bold h5 title-color"> Tipo: </span>
                      <span class="h5">
                        {{
                          typeof CotizacionData?.cotizacion?.nuevo ==
                          "undefined"
                            ? "--"
                            : CotizacionData.cotizacion.nuevo
                            ? "NUEVO"
                            : "USADO"
                        }}
                      </span>
                    </div>
                  </template>
                </div>
              </v-card-text>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <div class="pt-3 sticky">
            <v-slide-y-transition mode="out-in">
              <!-- :exclusividades="exclusivityList" -->
              <MyFilters
                v-if="pageLoaded"
                :priceLimit="priceLimit"
                :lowerPriceLimit="lowerPriceLimit"
                :companyList="companyList"
                :deducibleList="deducibleList"
                :vigencias="vigenciaList"
                :sort-price="sorters.price"
                :product-list="productTypeList"
                :priceSymbol="priceSymbol"
                @pricevalue="(filters.precio = $event), filterItems()"
                @filter-companies="(filters.compania = $event), filterItems()"
                @filter-coberturas="(filters.cobertura = $event), filterItems()"
                @filter-deducibles="
                  (filters.deducibles = $event), filterItems()
                "
                @filter-vigencia="(filters.vigencia = $event), filterItems()"
                @filter-products="(filters.productos = $event), filterItems()"
                @sort-price="(sorters.price = !sorters.price), filterItems()"
              />
              <!-- @Filter-exclusividad="
              (filters.exclusividad = $event), filterItems()
            " -->
              <v-skeleton-loader v-else type="image@2" tile>
              </v-skeleton-loader>
            </v-slide-y-transition>
          </div>
        </v-col>
        <v-col cols="12" md="9">
          <div class="flex-grow-1">
            <!-- LOADING SCREEN WHILE WAIT FOR THE RESPONSE -->
            <v-slide-x-transition mode="out-in">
              <v-layout v-if="loading">
                <v-row no-gutters>
                  <v-col
                    xl="2"
                    lg="3"
                    md="4"
                    xs="6"
                    v-for="i in $vuetify.breakpoint.xl ? 12 : 8"
                    :key="i"
                    class="global-products pa-xl-2 pa-lg-2 pa-md-0"
                  >
                    <v-skeleton-loader
                      type="image, actions"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-layout>

              <!-- PRODUCTS LOADED AFTER IT GOT THE RESPONSE -->
              <v-container flat v-else-if="Items.length > 0">
                <v-row class="d-flex align-stretch">
                  <v-col
                    v-for="item in Items"
                    :key="item.Identification"
                    xl="2"
                    lg="3"
                    md="4"
                    xs="6"
                    class="pa-2"
                  >
                    <products
                      icon="mdi-cash-multiple"
                      sales="MAS ECONOMICO"
                      descount="10% OFF"
                      :loading="item.loading"
                      :Information="item"
                      :actionButton="Vermas"
                      :disabled-continue="
                        CotizacionData?.cotizacion?.estado == 'FAL'
                      "
                      @CreatePropuesta="CreatePropuesta"
                    />
                  </v-col>
                </v-row>
              </v-container>
              <v-container v-else>
                <v-row>
                  <v-col cols="12" md="8" offset-md="2">
                    <v-card class="light br-6 invisible" elevation="0">
                      <v-card-text>
                        <div
                          class="d-flex justify-center flex-column text-center"
                        >
                          <v-icon size="64">mdi-table-search</v-icon>
                          <h4 class="semiBold my-5">
                            No se ha encontrado ofertas.
                          </h4>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-slide-x-transition>
            <!-- <v-layout wrap v-if="!loading">
            <v-flex
              lg3
              xl2
              v-for="item in Items"
              :key="item.Identification"
              class="global-products d-flex justify-center flex-wrap pa-xl-2 pa-lg-1 pa-md-0 pa-sm-0"
            >
              <products
                icon="mdi-cash-multiple"
                sales="MAS ECONOMICO"
                descount="10% OFF"
                :Information="item"
                :actionButton="Vermas"
              > -->
            <!-- <template v-slot:header>
                <v-checkbox
                  :disabled="ItemToBeCompared.length == 4 ? true : false"
                  dense
                  :color="btnColor"
                  :value="item"
                  v-model="ItemToBeCompared"
                  :label="item.plan"
                  hide-details
                  class="my-1"
                >
                  <template v-slot:label
                    ><span class="caption" :style="{ color: btnColor }"
                      >Comparar
                    </span>
                  </template>
                </v-checkbox>
              </template> -->
            <!-- </products>
            </v-flex>
          </v-layout> -->

            <!-- <span class="red">{{ index }}</span> -->
          </div>
        </v-col>
      </v-row>

      <v-layout wrap class="d-flex justify-center">
        <v-flex lg12 md12 xl11>
          <div class="d-flex pl-sm-4">
            <!-- <v-spacer></v-spacer>
          <div class="d-flex pr-sm-4">
            <AlertAdded
              show="true"
              message="Estos son los productos guardados en el día"
              :date="CotizacionData" -->
            <!-- /> -->
            <!-- <v-badge
              overlap
              :content="Cart.length"
              :value="Cart.length"
              offset-x="25"
              offset-y="15"
              class="mt-2"
            > -->
            <!-- <v-icon large class="ml-6 mb-5 m-icon">bi bi-cart</v-icon> -->
            <!-- </v-badge> -->
            <!-- </div> -->
          </div>
          <div>
            <ComparisonBar
              v-if="ItemToBeCompared.length > 0"
              @input="compare = $event"
              :comparisonData="ItemToBeCompared"
              :Comparar="Comparar"
              :deleteAll="deleteAll"
            />
          </div>
          <div class="d-flex justify-end mr-3 red--text">
            {{ errormsg }}
          </div>
        </v-flex>

        <v-flex
          lg3
          md3
          xl2
          class="mt-10 d-flex justify-lg-center justify-start"
        >
        </v-flex>
      </v-layout>

      <modalDescuento
        v-if="openSolicitarModal"
        :Info="DetailsData"
        :closeBtn="closeButton"
        :Ofertas="CotizacionData"
        :open="openSolicitarModal"
        @descuentosolicitado="descuentoSolicitado"
      />

      <Modal
        :open="DetailsModal"
        :Infor="DetailsData"
        @close="DetailsModal = false"
        :Descuento="Desconto"
        @CreatePropuesta="CreatePropuesta"
        @download="GenerateQuot($event, id)"
        @share="sendQuot($event, id)"
        :loadShare="loadShare"
        :loadingpropuesta="loadingpropuesta"
        :loadingquot="loadQuot"
        :descuentosolicitado="descuentosolicitado"
        :cotizacion="CotizacionData?.cotizacion ?? {}"
        :disabled-continue="CotizacionData?.cotizacion?.estado == 'FAL'"
      />
      <InspeccionarModal
        v-if="inspectModal"
        :open="inspectModal"
        :action="closeModal"
        :dataUser="CotizacionData?.cotizacion ?? {}"
      />
      <snackbar :snackbar="isPosted" />
    </div>
    <div v-else>
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <div class="d-flex flex-column">
              <span class="h1">Oops! Ha ocurrido un error</span>
              <span class="h2 mt-6"
                >Nuestro sistema ha presentado problemas al cargar la
                información. Regrese e inténtelo nuevamente.</span
              >
              <div class="text-center mt-4">
                <v-btn
                  color="primary"
                  class="text-none"
                  rounded
                  width="225"
                  @click="reload()"
                >
                  Volver a intentarlo
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <v-img src="@/assets/svg/ilustracion_error-01.svg"></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import InspeccionarModal from "@/components/Modal/Modal-Inspeccionar";
// import AlertAdded from "@/components/Alert/Alert-addToCart";
import MyFilters from "@/components/Cards/Cotizacion/Filter-card";
import products from "@/components/Cards/Cotizacion/Products-card";
import ComparisonBar from "@/components/Cards/Comparison-bar.vue";
import Modal from "@/components/Modal/Modal-product-details";
import axios from "axios";
import modalDescuento from "@/components/Modal/Modal-descuento";

import snackbar from "@/components/Snackbar/Snack-error";
import { GenShare } from "@/Mixins/GenAndShareQuotMixin.js";
import CountryMixin from "@/Mixins/CountryMixin.js";
import { obtenerCotizacionOfertas } from "@/services/cotizacion.js";

export default {
  name: "CotizacionesResultPage",
  mixins: [GenShare, CountryMixin],
  props: {
    id: {
      type: [String, Number],
      default: "",
    },
  },
  components: {
    MyFilters,
    // AlertAdded,
    products,
    ComparisonBar,
    Modal,
    modalDescuento,
    snackbar,
    InspeccionarModal,
  },
  data() {
    return {
      // ==  Mostrar mensaje de error ==
      withError: false,

      // == Objeto de Cotizacion
      CotizacionData: null,
      // == Listado de ofertas ==
      Items: [],

      // == Filtros ==
      filters: {
        precio: [],
        compania: [],
        deducibles: [],
        //exclusividad: "",
        vigencia: "",
        productos: [],
      },
      // == Ordenamiento
      sorters: {
        price: true,
      },
      // == Elementos de cada filtro ==
      companyList: [],
      //exclusivityList: [],
      deducibleList: [],
      vigenciaList: [],
      productTypeList: [],

      InspeccionSuccess: false,
      descuentosolicitado: false,

      // == Price Values ==
      priceLimit: 0,
      lowerPriceLimit: 0,
      priceSymbol: "",

      // == Elementos de carga ==
      loading: false, // Carga de la pagina
      inpectLoading: false, // Carga de la modal de inspeccion
      pageLoaded: false, // Pagina cargada
      loadingpropuesta: false, // Carga de crear propuesta

      // Inspect Modal
      inspectModal: false,
      openSolicitarModal: false,
      DetailsModal: false,
      errormsg: "",
      DetailsData: [],
      ItemToBeCompared: [],
    };
  },
  created() {
    this.setup();
  },
  methods: {
    ...mapActions("Proposal", ["saveProposalData", "getProposal"]),

    goBack() {
      const document_number = this.CotizacionData?.cotizacion?.numero_documento;
      const document_type = this.CotizacionData?.cotizacion?.tipo_documento;
      if (document_number && document_type) {
        this.$router.push({
          name: "UserDetail",
          params: { document_number, document_type },
        });
      } else {
        this.$router.push({
          name: "dashboard",
        });
      }
    },

    reload() {
      location.reload();
    },

    setup() {
      this.loading = true;
      obtenerCotizacionOfertas(this.id)
        .then((response) => {
          this.CotizacionData = response.cotizacionData;
          // Filtro de limite de precio
          this.priceLimit = response.priceLimit;
          this.lowerPriceLimit = response.lowerPriceLimit;
          this.priceSymbol = response.priceSymbol;
          // Listado de filtro de companias
          this.companyList = response.companyList;
          // Listado filtro de exclusividad
          //this.exclusivityList = response.exclusivityList;
          // Listado filtro de deducible
          this.deducibleList = response.deducibleList;
          // Listado filtro de vigencia
          this.vigenciaList = response.vigenciaList;
          // Listado filtro de tipo de productos
          this.productTypeList = response.productTypeList;
          // Se envia tag si tiene descuento pendiente
          this.descuentoSolicitado(
            response.cotizacionData.cotizacion.tieneDescuento
          );

          // == Filtrar items ==
          this.filterItems();
          // this.Items = res.data.data.tarifas.ofertas;
        })
        .catch((message) => {
          this.withError = true;
          this.$store.commit("setSnackbar", {
            active: true,
            text: message,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          window.scrollTo(0, 0);
          this.loading = false;
          this.pageLoaded = true;
        });
    },

    // === Metodo de filtrado multiple ===
    filterItems() {
      let items = this.CotizacionData?.tarifas?.ofertas ?? [];
      // === Filtrar por precio Local ===
      if (this.filters.precio?.length > 0) {
        items = items.filter(
          (e) =>
            e.moneda_local.precio >= this.filters.precio[0] &&
            e.moneda_local.precio <= this.filters.precio[1]
        );
      }
      // === Filtrar por cobertura ===
      // this.FilterCobertura()
      // === Filtrar por compania ===
      if (this.filters.compania?.length > 0) {
        items = items.filter((e) => this.filters.compania.includes(e.compania));
      }
      // == Filtrar por deducibles ===
      if (this.filters.deducibles?.length > 0) {
        items = items.filter((e) =>
          this.filters.deducibles.includes(e.deducible)
        );
      }
      // === Filtrar por exlusividad ===
      /* if (this.filters.exclusividad?.length > 0) {
        items = items.filter((e) =>
          e.tipo_exclusividad
            ? this.filters.exclusividad.includes(
                e.tipo_exclusividad.exclusividad
              )
            : false
        );
      } */
      // === Filtrar por vigencia ===
      if (this.filters.vigencia?.length > 0) {
        items = items.filter((e) =>
          e.objeto_vigencia
            ? this.filters.vigencia.includes(e.objeto_vigencia.vigencia)
            : false
        );
      }
      // === Filtrar por tipo de producto ===
      if (this.filters.productos?.length > 0) {
        items = items.filter((e) =>
          e.tipo_producto
            ? this.filters.productos.includes(e.tipo_producto._id)
            : false
        );
      }
      // === Establecer orden ===
      items.sort((a, b) => {
        const aVal = a?.moneda_local?.precio ?? 0;
        const bVal = b?.moneda_local?.precio ?? 0;
        return this.sorters.price ? aVal - bVal : bVal - aVal;
      });

      this.Items = items;
    },

    // === Filtro de Coberturas ===
    // FilterCobertura(i) {
    //   this.filterByRange();
    //   if (i) {
    //     const filter = this.Items;
    //     const filtered = [];
    //     filter.filter((filter) => {
    //       for (
    //         let index = 0;
    //         index < filter.coberturas_exclusiones.length;
    //         index++
    //       )
    //         if (filter.coberturas_exclusiones[index].titulo === i) {
    //           filtered.push(filter);
    //         }
    //     });
    //     this.Items = filtered;
    //   } else {
    //     return;
    //   }
    // },

    descuentoSolicitado(i) {
      this.descuentosolicitado = i;
    },
    deleteAll() {
      this.ItemToBeCompared = [];
    },
    CreatePropuesta(i) {
      i.loading = true;
      this.loadingpropuesta = true;

      // Verificar si la compania tiene medios de pago
      if (i?.medio_pago?.length <= 0) {
        this.loadingpropuesta = false;
        i.loading = false;
        this.$store.commit("setSnackbar", {
          active: true,
          text: `La compañía ${
            i?.compania_descripcion ?? ""
          } no tiene medios de pago configurados.`,
          top: true,
          right: true,
          color: "error",
        });
        return false;
      }

      // Establecer formulario de envio
      const user = this.CotizacionData.cotizacion;
      const form = {
        cotizacion: this.CotizacionData.tarifas.numero_cotizacion,
        nombre: user.cliente,
        primer_apellido: user.cliente,
        tipo_documento: user.tipo_documento,
        numero_documento: user.numero_documento,
        plan: i.plan,
      };

      // Intentar crear la propuesta
      axios
        .post("/propuestas", form)
        .then((response) => {
          const propuesta = response.data.data.propuesta;

          this.$router.push({
            name: "ShoppingCart",
            params: { propuesta: propuesta.numero_propuesta },
          });
        })
        .catch(() => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: "Ha ocurrido un error al intentar crear la Propuesta.",
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => {
          i.loading = false;
          this.loadingpropuesta = false;
        });
    },

    Desconto(value) {
      if (value) {
        this.DetailsModal = false;
        this.openSolicitarModal = true;
      }
    },

    closeButton() {
      this.openSolicitarModal = false;
    },

    Vermas(value) {
      this.DetailsData = value;
      this.DetailsModal = true;
    },

    Comparar() {
      this.$store.commit("SET_COMPARISON_DATA", this.ItemToBeCompared);
      this.$store.commit("SET_OFERTA_DATA", this.CotizacionData);
      this.$store.commit("SET_USER_QUOT", this.CotizacionData.cotizacion);
      this.$router.push({
        name: "compare",
        params: {
          data: this.ItemToBeCompared,
          oferta: this.CotizacionData,
          id: this.id,
          user: this.CotizacionData.cotizacion,
        },
      });
    },

    Inspeccionar() {
      this.inpectLoading = true;
      const oferta = this.CotizacionData.cotizacion;
      axios({
        url: "inspecciones",
        method: "POST",
        data: {
          producto: oferta.producto,
          nuevo: oferta.nuevo,
          marca: oferta.marca,
          modelo: oferta.modelo,
          patente: oferta.patente,
          categoria: oferta.categoria,
          anio: oferta.anio,
          categoria_descripcion: oferta.categoria_descripcion,
          numero_documento: oferta.numero_documento,
          tipo_documento: oferta.tipo_documento,
          cliente: oferta.cliente,
          precio_venta: oferta.precio_venta,
          peso_carga: oferta.peso_carga,
          acopio: oferta.acopio,
          frigorifico: oferta.frigorifico,
          numero_cotizacion: oferta.numero_cotizacion,
          email: "jperez@mesos.cl",
          telefono: "+56996544620",
        },
      })
        .then(() => {
          (this.InspeccionSuccess = true),
            (this.inspectModal = true),
            (this.inpectLoading = false);
        })
        .catch((err) => {
          const messege = err.response.data.data.lista_mensaje[0].mensaje;
          this.inpectLoading = false;
          var mensaje_error = messege.split(" ");
          mensaje_error[3] = "inspección";
          mensaje_error[5] = "vehículo";
          var mensaje = mensaje_error.join(" ");
          this.$store.commit("POST_ERROR", mensaje);
        });
    },

    closeModal() {
      this.inspectModal = !this.inspectModal;
    },

    handlerClickContinue() {
      const Cotizacion = this.CotizacionData.cotizacion;
      const estado = Cotizacion.estado;
      let name,
        params = {},
        query = {};
      if (estado === "SEL") {
        name = "ShoppingCart";
        params.propuesta = Cotizacion.numero_propuesta;
      } else if (estado === "FAL") {
        name = "Contract";
        params.id = Cotizacion.numero_propuesta;
        query.paso = Cotizacion.step ?? 1;
      }
      this.$router.push({ name, params, query });
    },
  },

  computed: {
    isPosted() {
      return this.$store.getters.isPosted;
    },
  },
};
</script>

<style scoped>
.sticky {
  position: sticky;
  top: 150px;
}
.headerSticky {
  position: sticky;
  top: 52px;
  z-index: 100;
}
</style>
